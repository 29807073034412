import { aIAssistantConstants, cartTabConstants, constants } from '../constants'
import store from './../store/store'

const leftSidebarToggleAction = () => async dispatch => {
  dispatch({
    type: constants.LEFT_SIDEBAR_TOGGLE,
    payload: !store.getState().leftToggle.leftToggle
  })
}

const rightSidebarToggleAction = () => async dispatch => {
  dispatch({
    type: constants.RIGHT_SIDEBAR_TOGGLE,
    payload: !store.getState().rightToggle.rightToggle
  })
}

const hideRightSidebarToggleAction = () => async dispatch => {
  dispatch({
    type: constants.RIGHT_SIDEBAR_TOGGLE,
    payload: false
  })
}

const addFolderToggleAction = data => async dispatch => {
  dispatch({
    type: constants.FOLDER_TOGGLE,
    payload: { toggle: !store.getState().folderToggle.folderToggle, data: data }
  })
}

const renderApiAgain = data => async dispatch => {
  return dispatch({
    type: constants.RENDER_AGAIN,
    payload: { name: data }
  })
}

const addSongToggleAction = data => async dispatch => {
  dispatch({
    type: constants.SONG_BLOCK_TOGGLE,
    payload: { toggle: false, type: '' }
  })
  dispatch({
    type: constants.SONG_TOGGLE,
    payload: { toggle: !store.getState().songToggle.songToggle, data: data }
  })
}

const addNotebookToggleAction = data => async dispatch => {
  dispatch({
    type: constants.NOTEBOOK_TOGGLE,
    payload: { toggle: !store.getState().notebook.notebookToggle, data: data }
  })
}

const closeNotebookToggleAction = data => async dispatch => {
  dispatch({
    type: constants.NOTEBOOK_TOGGLE,
    payload: { toggle: false, data: null }
  })
}

const addSongBlockToggleAction = () => async dispatch => {
  dispatch({
    type: constants.SONG_TOGGLE,
    payload: {
      toggle: !store.getState().songBlockToggle.songBlockToggle,
      data: null
    }
  })
  dispatch({
    type: constants.SONG_BLOCK_TOGGLE,
    payload: {
      toggle: !store.getState().songBlockToggle.songBlockToggle,
      type: 'Add_SONG_BLOCK'
    }
  })
}

const colorToggleAction = () => async dispatch => {
  dispatch({
    type: constants.BACKGROUND_COLOR_TOGGLE,
    payload: { toggle: false }
  })
  dispatch({
    type: constants.COLOR_TOGGLE,
    payload: { toggle: !store.getState().colorToggle.colorToggle }
  })
}

const backgroundColorToggleAction = () => async dispatch => {
  dispatch({
    type: constants.COLOR_TOGGLE,
    payload: { toggle: false }
  })
  dispatch({
    type: constants.BACKGROUND_COLOR_TOGGLE,
    payload: {
      toggle: !store.getState().backColorToggle.backColorToggle
    }
  })
}

const colorToggleHideAction = () => async dispatch => {
  dispatch({
    type: constants.COLOR_TOGGLE,
    payload: { toggle: false }
  })
  dispatch({
    type: constants.BACKGROUND_COLOR_TOGGLE,
    payload: { toggle: false }
  })
}

const selectButtonToggleHideAction = () => async dispatch => {
  dispatch({
    type: constants.SELECT_BTN_TOGGLE,
    payload: { toggle: !store.getState().selectBtnToggle.selectBtnToggle }
  })
  dispatch({
    type: constants.RESET_SELECT_FOLDERS_DATA
  })
}

const assistantFooterToggleAction = (
  boolean,
  generatedContent
) => async dispatch => {
  dispatch({
    type: aIAssistantConstants.AI_ASSISTANT_TAB_ACTIVE,
    payload: { boolean: boolean, ai_generated: generatedContent || null }
  })
}

const assistantFooterDeleteAction = deleteAction => async dispatch => {
  dispatch({
    type: aIAssistantConstants.AI_ASSISTANT_DELETE,
    payload: { ai_generated: null, deleteAction: deleteAction }
  })
}

export {
  leftSidebarToggleAction,
  rightSidebarToggleAction,
  addFolderToggleAction,
  addSongToggleAction,
  colorToggleAction,
  backgroundColorToggleAction,
  colorToggleHideAction,
  addSongBlockToggleAction,
  closeNotebookToggleAction,
  selectButtonToggleHideAction,
  assistantFooterToggleAction,
  assistantFooterDeleteAction,
  addNotebookToggleAction,
  hideRightSidebarToggleAction,
  renderApiAgain
}
