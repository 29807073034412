import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { rightSidebarToggleAction } from '../../services/actions/toggle.action'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { a11yProps } from '../../utils/Tabs'
import { Box } from '@mui/material'
import { CartIcon, IdeaIcon, RecordingIcon } from '../../helper/icons'
const RightHeader = ({ value, handleChange }) => {
  const dispatch = useDispatch()

  return (
    <>
      <div className="right-icon-close-new">
        <img
          src="assets/images/right-bar-close-new.svg"
          onClick={() => dispatch(rightSidebarToggleAction())}
          className="right-icon-close"
          alt=""
          width="33px"
        />
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="right-header-icons">
              <Box sx={{ width: '100%' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    className="right-top-header"
                    icon={<IdeaIcon />}
                    aria-label="Idea"
                    {...a11yProps(0)}
                  />
                  {/* <Tab
                    className="right-top-header"
                    icon={<FavIcon />}
                    aria-label="Favorite"
                    {...a11yProps(1)}
                  /> */}
                  <Tab
                    className="right-top-header"
                    icon={<RecordingIcon />}
                    aria-label="Recording"
                    {...a11yProps(1)}
                  />

                  <Tab
                    className="right-top-header"
                    icon={<CartIcon />}
                    aria-label="Cart"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RightHeader
