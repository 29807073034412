import { useEffect, useState } from 'react';
import { Api, socket_url } from '../helper/ApiList';
import { useDispatch } from 'react-redux';
import { loaderAction } from '../services/actions/loader.action';
import { useAlert } from 'react-alert';
import { io } from 'socket.io-client';
import { useRef } from 'react';
import '../price.css';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SuccessToast, toast } from '../components/Toast/Toast';

const options = {
  error: '',
  success: ''
};
const Success = props => {
  const socket = useRef();
  const { loader } = useSelector(state => state.loader);

  const [connected, setConnected] = useState(false);
  const [msg, setMsg] = useState(options);

  // const [searchParams] = useParams();
  const params = new URLSearchParams(window.location.search);
  const session_id = params.get('session_id');
  const beatId = params.get('beatId');
  console.log(`Session ${session_id}, beatId ${beatId}`);
  const type = params.get('type');
  useEffect(() => {
    dispatch(loaderAction(true));
    setMsg(options);

    socket.current = io(socket_url, { transports: ['websocket'] });
    socket.current.on('connect', () => {
      setConnected(true);
      dispatch(loaderAction(false));

      console.log('socket connected');
    });
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    const retrieveSubscription = async () => {
      dispatch(loaderAction(true));
      try {

        if (!beatId) {

          const url = `${Api.retrieve_subscription}?session_id=${session_id}`;
          const { data } = await axios.get(url);
          socket.current.emit('payment_success', {
            token: data?.data?.accessToken,
            userId: data?.data?.users?._id
          });
          setMsg({ success: data?.message });
          toast.success('Subscription created successfully');
        } else {
          const url = `${Api.retrieve_beat_payment}`;
          const { data } = await axios.post(url, {
            sessionId: session_id,
            beatId
          });

          console.log(data, "beat payment fetch successfully");

          socket.current.emit('beat_payment_success', {
            token: "",
            userId: data?.users?._id
          });
          setMsg({ success: data?.message });
          SuccessToast(data?.message);
        }

        setTimeout(() => {
          window?.close();
          if (window?.opener?.progressWindow) {
            window?.opener?.progressWindow.close();
          }
          window?.close();
        }, 2000);
      } catch (error) {
        let errorMsg = error?.response?.data?.message || error?.message;
        setMsg({ error: errorMsg });
        toast.error(errorMsg);

        console.log(error, 'Error while retrieving payment');
      }
      dispatch(loaderAction(false));
    };
    session_id && connected && retrieveSubscription();
  }, [session_id, connected]);

  return (
    <div className="vh-100">
      <div className="h-100 d-flex flex-column align-items-center justify-content-center">
        <p className={` ${msg?.error ? 'text-danger' : 'text-success'}`}>
          {msg?.error || msg?.success || ''}
        </p>
        <div>
          <br />
          {!loader && !type && (
            <Link to="/">
              <Button>Close</Button>
            </Link>
          )}

          {!loader && type && <Button variant="secondary">Press Back</Button>}
        </div>
      </div>
    </div>
  );
};

export default Success;
