import React, { useEffect, useMemo, useState } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { a11yProps, TabPanel } from '../../../utils/Tabs';
import { ErrorToast, SuccessToast } from '../../Toast/Toast';
import { putMethod } from '../../../helper/ApiMethods';
import { Api } from '../../../helper/ApiList';
import CartWrapper from './CartWrapper';
import {
  filterBeats,
  filterItemsByCategory
} from '../../../utils/commonFunctions';
import { useSelector, useDispatch } from 'react-redux';
import { getMethod } from '../../../helper/ApiMethods';
import { loaderAction } from '../../../services/actions';
import { constants } from '../../../services/constants';

const Cart = () => {
  const { token } = useSelector(state => state.auth);
  const { recallApiName } = useSelector(state => state.recallApi);
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);
  const [search, setSearch] = useState('');
  const [activeId, setActiveId] = useState(null);
  const [activeCard, setActiveCard] = useState('All');

  const [beats, setBeats] = useState({
    beats: [],
    all: []
  });

  const handleChange = (event, newValue) => {
    setActiveId(null);
    setValue(newValue);
  };

  const handleSearch = e => {
    setActiveId(null);
    if (beats?.all?.length > 0) {
      setSearch(e.target.value);
      const newArr = beats?.all?.filter(obj =>
        Object.values(obj).some(val =>
          val?.toString()?.toLowerCase()?.includes(e.target.value.toLowerCase())
        )
      );
      setBeats({
        ...beats,
        beats: newArr
      });
    }
  };

  const handleReset = () => {
    setSearch('');
    setBeats({
      ...beats,
      beats: beats.all
    });
  };

  const filterItems = name => {
    setActiveId(null);
    setActiveCard(name);
    const list = filterItemsByCategory(beats.all, name);

    setBeats({ ...beats, beats: list });
  };

  useEffect(() => {
    const getAllBeats = async () => {
      try {
        dispatch(loaderAction(true));

        const { data } = await getMethod(token, Api.get_beats);

        const result = data?.map(ele => {
          return {
            ...ele,
            created_at: ele?.item_date,
            songName: ele?.item_name,
            item_sample_path: ele?.item_sample_path?.replace('http:', 'https:')
          };
        });
        setBeats({
          all: result,
          beats: result
        });

      } catch (error) {
        console.error('Error while fetching beats: ' + error);
      } finally {
        dispatch({
          type: constants.RENDER_AGAIN,
          payload: {
            recallApiName: ''
          }
        });
        dispatch(loaderAction(false));
      }
    };
    getAllBeats();
  }, [recallApiName]);


  const tabIndices = ['all', 'free', 'premium', 'favs'];

  const toggleFavorite = async item => {
    const { item_id, isFav } = item;
    const matchIndex = beats.all?.findIndex(ele => ele.item_id === item_id);

    if (matchIndex === -1) {
      // Item not found, handle this case if needed
      console.warn('Item not found');
      return;
    }

    // Create a new array with the updated item
    const updatedAll = [...beats.all];
    updatedAll[matchIndex] = {
      ...updatedAll[matchIndex],
      isFav: !isFav
    };

    // Update the state with the new array
    setBeats({
      all: updatedAll,
      beats: updatedAll
    });

    try {
      const { data } = await putMethod(token, Api.addToFav, {
        cartId: item?.item_id
      });

      SuccessToast(data?.message);
    } catch (error) {
      ErrorToast(error?.response?.data?.message || error?.message);
    }
  };

  const TabPanelWrapper = ({ value, index, ...props }) => (
    <TabPanel className="" value={value} index={index}>
      <CartWrapper
        activeId={props.activeId}
        beats={props.beats}
        handleReset={handleReset}
        handleSearch={handleSearch}
        search={search}
        setActiveId={props.setActiveId}
        setBeats={setBeats}
        allList={beats.all}
        toggleFavorite={toggleFavorite}
        filterItems={filterItems}
        activeCard={activeCard}
      />
    </TabPanel>
  );

  return (
    <div>
      <div className="gray-section-right-bar">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="right-side-tab mt-2">
                <Box sx={{ width: '100%' }}>
                  <Box className="tabs-outer-bg">
                    <Tabs
                      className="round-blue"
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        className="right-tab-custom"
                        label="All Beats"
                        {...a11yProps(0)}
                      />
                      <Tab
                        className="right-tab-custom"
                        label="Free"
                        {...a11yProps(1)}
                      />
                      <Tab
                        className="right-tab-custom"
                        label="Premium"
                        {...a11yProps(2)}
                      />
                      <Tab
                        className="right-tab-custom"
                        label="Favs"
                        {...a11yProps(3)}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <div className="container">
                      <div className="row mt-2">
                        <div className="col-lg-12 text-center">
                          <p className="m-0">Make Hit Music Beat Store</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-lg-12 text-center">
                        <div className="search-bar-custom"></div>
                      </div>
                    </div> */}
                  </TabPanel>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
      {tabIndices.map((ele, index) => (
        <TabPanelWrapper
          key={index}
          value={value}
          index={index}
          activeId={activeId}
          beats={filterBeats[ele](beats?.beats)}
          setActiveId={setActiveId}
        />
      ))}
    </div>
  );
};

export default Cart;
