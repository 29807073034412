import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  currencyFormat,
  handleBuyMore,
  handleOpenWindow
} from '../../../utils/commonFunctions';
import { postMethod } from '../../../helper/ApiMethods';
import { useSelector } from 'react-redux';
import { Api } from '../../../helper/ApiList';
import CircularProgress from '@mui/material/CircularProgress';

const BeatList = ({
  beat,
  activeId,
  setActiveId,
  selectBeats,
  playerVolume,
  toggleFavorite,
  handleExport
}) => {
  const { token, user } = useSelector(state => state.auth);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loader, setLoader] = useState({
    id: null,
    loading: false
  });
  const audioRef = useRef(null);

  const active = useMemo(() => {
    const isActive = selectBeats.includes(beat?.item_id);

    return {
      icon:
        beat?.item_id === activeId && isPlaying
          ? 'assets/images/pause.svg'
          : 'assets/images/play-border-new.svg',
      activeClass: isActive ? 'active' : '',
      heartIcon: `assets/images/${beat?.isFav ? 'fill-heart' : 'heart'}.svg`
    };
  }, [activeId, beat, isPlaying, selectBeats]);

  const togglePlayPause = () => {
    setActiveId(beat?.item_id);
    if (isPlaying && activeId === beat?.item_id) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (audioRef.current && activeId) {
      if (isPlaying && activeId === beat?.item_id) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play();
        setIsPlaying(true);
      }
    }
    return () => { };
  }, [activeId]);

  // Apply the volume level whenever it changes
  useEffect(() => {
    if (audioRef?.current) {
      audioRef.current.volume = playerVolume;
    }
  }, [playerVolume]);

  const handleDownload = async () => {
    // handleSongSelect(beat);
    setLoader({
      id: beat?.item_id,
      loading: true
    });
    const render = await handleExport(beat);
    setLoader({
      id: null,
      loading: false
    });
  };

  const handlePurchaseBeat = async () => {
    if (beat?.item_price === '0.00' || beat?.isPaid) {
      handleDownload();
      return;
    }

    const url = Api.create_beat_checkout;
    const { data } = await postMethod(token, url, {
      price: beat?.item_price,
      productName: beat?.item_name,
      beatId: beat?.item_id
    });
    if (data) {
      handleOpenWindow(data);
    }
  };

  const amountLabel = () => {
    return {
      isFree: beat?.item_price === '0.00',
      amount_label: beat?.isPaid
        ? 'Paid'
        : beat?.item_price === '0.00'
          ? 'Free'
          : currencyFormat(beat?.item_price)
    };
  };

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement) {
      const handleTimeUpdate = () => {
        if (audioElement.currentTime >= 59 && !beat?.isPaid) {
          // Trigger your event here
          // console.log('30 seconds reached!');
          setActiveId(null);
          setIsPlaying(false);
          // if (user?.user?.account_type === 'free') {
          //   toast.info(
          //     'Free users can listen to the beats for 60 seconds only.'
          //   );
          // }

          // Optionally remove the event listener if you only need to trigger it once
          audioElement.removeEventListener('timeupdate', handleTimeUpdate);
        }
      };

      audioElement.addEventListener('timeupdate', handleTimeUpdate);

      // Cleanup event listener on component unmount
      return () => {
        audioElement.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [beat]);

  return (
    <div className={`Beats-Producer ${active.activeClass} `}>
      <div className={`track-list cursor border-0`}>
        <div>
          <div className="d-flex">
            <img
              className="recording_play_icon"
              src={active.icon}
              alt="Play/Pause Icon"
              onClick={e => {
                e.stopPropagation();
                togglePlayPause();
              }}
            />
            <div className="song-item-wrapper">
              <div className="song-track fw-600">{beat?.item_name}</div>
              <div className="beat-author">{beat?.item_duration || ""}</div>
            </div>
          </div>
        </div>

        <div className="date-size-new">
          <img
            className="recording_play_icon"
            src={active.heartIcon}
            alt="Favorite Icon"
            onClick={() => toggleFavorite(beat)}
          />
          <div>
            {/* <p className="m-0">{beat?.item_duration}</p> */}

            <p
              className={`m-0 beat_amount cursor ${active.activeClass}`}
              onClick={() => handlePurchaseBeat()}
            >
              {amountLabel().amount_label}{' '}
              {loader.id === beat?.item_id && (
                <span className="beat-download-loader">
                  <CircularProgress />
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
      {activeId === beat?.item_id && (
        <audio
          className="d-none"
          ref={audioRef}
          src={beat?.item_sample_path}
          onEnded={() => setIsPlaying(false)}
        />
      )}
    </div>
  );
};

export default BeatList;
