import * as React from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { setSessionStorage } from '../../../helper/cookies'
import { filterOptions } from '../../../utils/commonOptions'
import { FilterIcon } from '../../../icons'

export default function CartFilter({ allList, setBeats }) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [filterType, setFilterType] = React.useState('')

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  function filterNewest() {
    // Sort the array based on the 'created_at' property in descending order

    let array = allList?.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    )
    setBeats({
      beats: array,
      all: array
    })
  }

  function filterOldest() {
    // Sort the array based on the 'created_at' property in ascending order
    let array = allList?.sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    )
    setBeats({
      beats: array,
      all: array
    })
  }

  function filterAToZ() {
    let array = allList.sort((a, b) => a.songName.localeCompare(b.songName))
    setBeats({
      beats: array,
      all: array
    })
  }

  function filterZToA() {
    let array = allList.sort((a, b) => b.songName.localeCompare(a.songName))
    setBeats({
      beats: array,
      all: array
    })
  }

  const handleFilter = (e, type) => {
    if (type === 'newest') {
      filterNewest()
    }
    if (type === 'oldest') {
      filterOldest()
    }
    if (type === 'aToZ') {
      filterAToZ()
    }
    if (type === 'zToA') {
      filterZToA()
    }
    setFilterType(type)

    setSessionStorage('recordingFilterType', type)

    e && handleClose(e)
  }

  //   React.useEffect(() => {
  //     const filterType = getSessionStorage('recordingFilterType')
  //     if (filterType && allList && allList?.length > 0) {
  //       const handleFilter = type => {
  //         if (type === 'newest') {
  //           filterNewest()
  //         }
  //         if (type === 'oldest') {
  //           filterOldest()
  //         }
  //         if (type === 'aToZ') {
  //           filterAToZ()
  //         }
  //         if (type === 'zToA') {
  //           filterZToA()
  //         }
  //       }
  //       setFilterType(filterType)

  //       handleFilter(filterType)
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [])

  return (
    <>
      <span
        className="filters"
        onClick={handleToggle}
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
      >
        <FilterIcon /> Sort By
      </span>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  // autoFocusItem={true}
                  className="filtered-menu"
                  // autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {filterOptions?.map(item => (
                    <MenuItem
                      onClick={e => handleFilter(e, item?.order)}
                      key={item?.order}
                      className={
                        filterType === item?.order ? 'active' : 'not-active'
                      }
                    >
                      {item?.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
