import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const AlertConfirm = ({
    isOpen,
    setIsOpen,
    handleConfirm,
    message,
    heading,
    leftBtnMessage = "Cancel",
    rightBtnMessage = "Confirm",
}) => {
    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <div>
            <Dialog
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="custom_modal">
                    {heading || ''}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message || ''}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="error">
                        {leftBtnMessage}
                    </Button>
                    <Button onClick={handleConfirm} color="success">
                        {rightBtnMessage}
                    </Button>


                </DialogActions>
            </Dialog>
        </div>
    );
};


export default AlertConfirm;