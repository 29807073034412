import React, { useState } from 'react'

import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import { DownloadIcon } from '../../icons'
import { useDispatch, useSelector } from 'react-redux'
import { leftSidebarToggleAction } from '../../services/actions/toggle.action'
import {
  allSongAction,
  recentSongAction,
  songAction
} from '../../services/actions/song.action'
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'
import { getRecordingAction } from '../../services/actions/mediaPlayerActions/mediaPlayer.action'
import { Api } from '../../helper/ApiList'
import { getMethod, postMethod } from '../../helper/ApiMethods'
import { loaderAction } from '../../services/actions/loader.action'
import { toast } from '../Toast/Toast'
import InputSearch from './InputSearch'

const fileAccept =
  '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf, .txt,'

export default function SearchInput() {
  const { songs, title, folderId, type } = useSelector(state => state.songs)
  const { recentSongs } = useSelector(state => state.recentSongs)
  const { token } = useSelector(state => state.auth)

  const [value, setValue] = useState('')

  const [preSongs, setPreSongs] = useState([])
  const dispatch = useDispatch()

  const handleSearch = e => {
    if (preSongs.length > 0) {
      setValue(e.target.value)
      const newArr = preSongs?.filter(obj =>
        Object.values(obj).some(val =>
          val?.toString()?.toLowerCase()?.includes(e.target.value.toLowerCase())
        )
      )
      dispatch(allSongAction(newArr, title, folderId, type))
    }
  }

  const handleReset = () => {
    setValue('')
    dispatch(allSongAction(preSongs, title, folderId, type))
  }

  const handleCreateImportSong = async e => {
    try {
      let file = e.target.files[0]

      dispatch(loaderAction(true))

      var bodyFormData = new FormData()
      bodyFormData.append('file', file)

      const result = await axios({
        method: 'post',
        url: process.env.REACT_APP_SCRIPTIVELY_READ_API,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      let newBody = {
        songHeading: file?.name,
        songDescription: result?.data?.data?.html
      }

      e.target.value = ''

      const { data, error } = await postMethod(token, Api.importFile, newBody)

      toastMsg(data, error)
    } catch (error) {
      dispatch(loaderAction(false))

      toast.error(error?.response?.data?.message)
    }
    dispatch(loaderAction(false))
  }

  const toastMsg = async (data, error) => {
    if (!error) {
      toast.success(data?.message)

      let temp = [...songs]

      temp.unshift(data?.data)
      dispatch(allSongAction(temp))
      let recentArr = [...recentSongs]
      recentArr.length > 2 && recentArr.pop()

      recentArr.unshift(data?.data)
      dispatch(recentSongAction(recentArr))

      let url = Api.getSongBlock + data?.data?._id
      const { data: songBlocks } = await getMethod(token, url)
      dispatch(songAction(data?.data, songBlocks))
      // dispatch(songAction())
      dispatch(getRecordingAction())
    }

    error && toast.error(error)
  }

  return (
    <>
      <div className="custom-search">
        <InputSearch
          onClick={() => setPreSongs(songs)}
          handleReset={handleReset}
          handleSearch={handleSearch}
          value={value}
        />
      </div>
      <div className="d-flex search-right-icons">
        <IconButton
          className="download_icon"
          aria-label="upload picture"
          component="label"
        >
          <input
            hidden
            accept={fileAccept}
            type="file"
            onChange={handleCreateImportSong}
          />
          <DownloadIcon />
        </IconButton>
        <img
          src="assets/images/left-bar-close.svg"
          alt=""
          srcSet=""
          onClick={() => dispatch(leftSidebarToggleAction())}
          className="left-bar-close-new"
          width={28}
        />
      </div>
    </>
  )
}
