import React from 'react'
import { InputBase, Paper } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const InputSearch = ({ onClick, handleReset, handleSearch, value, width }) => {
  return (
    <Paper
      component="form"
      sx={{
        // p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: width || 399
      }}
    >
      <img src="assets/images/search-status.svg" alt="" />
      <InputBase
        onClick={() => onClick()}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleSearch}
        className="search-bar"
        value={value}
        autoFocus={true}
      />
      {value && (
        <CloseIcon
          onClick={() => handleReset()}
          style={{ width: '0.8em', paddingRight: '5px', cursor: 'pointer' }}
        />
      )}
    </Paper>
  )
}

export default InputSearch
