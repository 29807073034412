import React, { useEffect, useState } from 'react'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import { SongBlockThreeDots } from '../../../DropDownMenus/DropDownMenus'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import FormControl from '@mui/material/FormControl'
import { TextField } from '@material-ui/core'

import { useStyles } from '../../../utils/styled'
import { putMethod } from '../../../helper/ApiMethods'
import { useSelector } from 'react-redux'
import { Api } from '../../../helper/ApiList'
import SongBlockRecording from './SongBlockRecording'
import { useAlert } from 'react-alert'
import { EditorContent, useEditor } from '@tiptap/react'
import { Color } from '@tiptap/extension-color'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Paragraph from '@tiptap/extension-paragraph'
import TextStyle from '@tiptap/extension-text-style'
import Document from '@tiptap/extension-document'
import FontFamily from '@tiptap/extension-font-family'
import TextAlign from '@tiptap/extension-text-align'
import Highlight from '@tiptap/extension-highlight'
import Text from '@tiptap/extension-text'
import Placeholder from '@tiptap/extension-placeholder'
import { FontSize } from '../../../utils/CustomExtensions'
import { chordStyles } from '../../../utils/commonFunctions'
import { toast } from '../../Toast/Toast'
import { useDispatch } from 'react-redux'

const Container = styled.div`
  background-color: ${props =>
    props.isDragDisabled
      ? 'lightgrey'
      : props.isDragging
      ? '#e5e4e4 !important'
      : 'white'};
`

const SongBlockItem = forwardRef((props, ref) => {
  const blockRef = useRef(null)

  let { index, blocks } = props
  const isDragDisabled = blocks._id === 'task-1'
  const { disabled } = useSelector(state => state.loader)
  const classes = useStyles()
  const [songBlockHeading, setSongBlockHeading] = useState('')
  const { token } = useSelector(state => state.auth)
  const [songBlockDescription, setSongBlockDescription] = useState(
    chordStyles(blocks?.songBlockDescription)
  )
  const [editAction, setEditAction] = useState(false)

  let editor = useEditor(
    {
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: 'Type here...'
        }),
        Document,
        Paragraph,
        Underline,
        TextStyle,
        Color,
        Text,
        FontFamily,
        FontSize,
        TextAlign.configure({
          types: ['heading', 'paragraph', 'span']
        }),
        Highlight.configure({ multicolor: true })
      ],
      content: chordStyles(blocks?.songBlockDescription),
      editable: !disabled,
      parseOptions: {
        enablePasteRules: false
      },
      onUpdate({ editor }) {
        setEditAction(true)
        setSongBlockDescription(chordStyles(editor.getHTML()))
      }
    },
    [blocks?.songBlockDescription]
  )

  useEffect(() => {
    setSongBlockHeading(blocks?.songBlockHeading)
    setSongBlockDescription(chordStyles(blocks?.songBlockDescription))
  }, [blocks])

  React.useEffect(() => {
    let timeoutId

    const handleUpdate = async () => {
      let body = {
        songBlockHeading,
        songBlockDescription
      }

      if (!songBlockHeading) return
      const { error } = await putMethod(
        token,
        Api.updateSongBlock + '/' + blocks?._id,
        body
      )

      error && toast.error(error)
    }

    const delayedUpdate = () => {
      handleUpdate()
    }

    const delayDebounceFn = () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(delayedUpdate, 500)
    }

    editAction && delayDebounceFn()

    return () => {
      clearTimeout(timeoutId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [songBlockHeading, songBlockDescription, editAction])

  // this function is used to send the ref from child to parent
  useImperativeHandle(ref, () => ({
    childFunction1() {
      // console.log('child function 1 called hello')
      let data = blockRef?.current?.childFunction1()
      if (editor) {
        editor['blockHeading'] = songBlockHeading || ''
      }
      return editor
    }
  }))

  return (
    <>
      <Draggable
        draggableId={blocks._id}
        isDragDisabled={isDragDisabled}
        index={index}
      >
        {(provided, snapshot) => (
          <Container
            ref={provided.innerRef}
            {...provided.draggableProps}
            isDragging={snapshot.isDragging}
            isDragDisabled={isDragDisabled}
            {...provided.dragHandleProps}
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="song-block">
                  <div className="song-box-header">
                    <div className="row">
                      <div className="col-12 block-heading-wrapper">
                        <FormControl fullWidth sx={{ mb: 0 }}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            // disableunderline={false}
                            name="songBlockHeading"
                            classes={{ notchedOutline: classes.input }}
                            onChange={e => setSongBlockHeading(e.target.value)}
                            className={`${classes.textField} song_heading_edit`}
                            value={songBlockHeading}
                            placeholder="Enter heading..."
                            InputProps={{
                              classes: { notchedOutline: classes.noBorder }
                            }}
                            onClick={() => setEditAction(true)}
                            onBlur={() => setEditAction(false)}
                            disabled={disabled}
                          />
                        </FormControl>

                        <div className="dots-and-drag">
                          <img
                            className="add-block-img"
                            src="assets/images/vertical-drag.svg"
                            alt=""
                            width={15}
                          />
                          <SongBlockThreeDots
                            block={blocks}
                            editor={songBlockDescription}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="block_editor">
                      <EditorContent editor={editor} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        )}
      </Draggable>
    </>
  )
})

export default SongBlockItem
