import { windowFeatures } from './commonOptions'

const shiftUpdateSongElement = async (songs, updatedData, singleSong, type) => {
  const index = songs?.findIndex(item => item?._id === singleSong?._id)

  if (type) {
    let songObj = songs[index]

    if (songObj) songs[index].songHeading = updatedData // songHeading
  } else {
    songs[index].songDescription = updatedData
    songs[index].notebookContent = updatedData
  }
  let element = songs[index]
  let temp = [...songs]
  return temp || []
  let arr = temp.filter(item => item?._id !== singleSong?._id)
  // arr.unshift(element)
  return arr
}

const get_random_words = data => {
  if (data) {
    let temp = [...data].sort(() => Math.random() - 0.5)
    return temp.slice(0, 20)
  } else {
    return []
  }
}

const camalize = function camalize(str) {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

const timeFormat = duration => {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600)
  var mins = ~~((duration % 3600) / 60)
  var secs = ~~duration % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = ''

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs
  return ret
}

const songBlockReOrder = data => {
  let initialData = {
    tasks: {},
    columns: {
      'column-1': {
        id: 'column-1',
        title: 'To do',
        taskIds: []
      }
    },
    // Facilitate reordering of the columns
    columnOrder: ['column-1']
  }
  let taskIds = []
  let tasks = {}
  if (data?.length > 0) {
    for (let i = 0; i < data.length; i++) {
      taskIds.push(data[i]._id)
      let temp = { ...tasks }
      temp[data[i]._id] = data[i]
      // _id: data[i]._id,

      tasks = temp
    }
    initialData.tasks = tasks
    initialData.columns['column-1'].taskIds = taskIds
    return initialData
  } else {
    return initialData
  }
}

const handleExportBackTrackAndRecordings = (url, name) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = url
  a.download = name
  a.click()
}

let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const options = [
  {
    value: 'month',
    name: 'Monthly'
  },
  {
    value: 'year',
    name: 'Yearly'
  }
]

const currencyFormat = price => {
  if (!price) return ''
  return USDollar.format(price)
}

const months = data => {
  const temp = options.find(item => item?.value === data?.interval)
  return temp?.name || ''
}

const month = data => {
  const temp = options.find(item => item?.value === data?.interval)
  return temp?.value || ''
}

const countDown = async () => {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      resolve(true)
    }, 4000)
  })
}

function getStringBetween(stri, start, end) {
  if (!stri) return ''
  const result = stri?.match(new RegExp(start + '(.*)' + end))

  if (result) {
    return result[1]
  }
  return ''
}

const chordStyles = html => {
  if (!html) return ''

  let matchText = getStringBetween(html, '<strong><s>', '</s></strong>')
  let matchStrWithHtml = `<strong><s>${matchText}</s></strong>`
  let replaceStr = `<strong style="position:'relative !important';"><s style="position:'absolute'; bottom:'18px'; font-size:'12px';"> ${matchText} </s></strong>`

  let str = html?.replaceAll(matchStrWithHtml, replaceStr)
  return str
  // return str?.replaceAll(`\n`, `</br>`)
}

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/android/i.test(userAgent)) {
    window.location.href = 'https://songwriterspad.com/spai-google-play/'
    return 'Android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    window.location.href = 'https://songwriterspad.com/spai-app-store/'
    return 'iOS'
  }
  return 'unknown'
}

function isMobile() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }
  return null
}

const detectSystemOs = () => {
  const operatingSystem = window?.navigator?.platform

  // Example usage:
  if (operatingSystem.includes('Win')) {
    return 'Windows'
  }
  if (operatingSystem.includes('Mac')) {
    return 'Mac'
  }
  return 'Web'
}

const filterBeats = {
  all: data => data,
  free: data => data?.filter(ele => ele?.item_price === '0.00'),
  premium: data => data?.filter(ele => ele?.item_price !== '0.00'),
  favs: data => data?.filter(ele => ele?.isFav)
}

function filterItemsByCategory(arr, category_name) {
  if (category_name === 'All') {
    return arr
  }
  return arr.filter(item =>
    item.categories.some(category => category.category_name === category_name)
  )
}

function getUniqueCategoryNames(arr) {
  let categoryNames = arr.flatMap(item =>
    item.categories.map(category => category.category_name)
  )

  if (categoryNames?.length !== 0) {
    categoryNames.push('All')
  }
  // Create a Set to get unique category names
  const uniqueCategoryNames = [...new Set(categoryNames)]

  // Sort the unique category names alphabetically
  return uniqueCategoryNames.sort()
}

const handleOpenWindow = url => {
  const isMac = localStorage.getItem('isMac')

  if (isMac) {
    handleBuyMore()
    return
  }

  const handle = window.open(url, 'Price Page', windowFeatures)
  if (!handle) {
    window.alert(
      "The window wasn't allowed to open, This is likely caused by built-in popup blockers."
    )
  }
}

const handleBuyMore = () => {
  const handle = window.open('/price', 'Price Page', windowFeatures)
  if (!handle) {
    window.alert(
      "The window wasn't allowed to open, This is likely caused by built-in popup blockers."
    )
  }
}

export {
  shiftUpdateSongElement,
  timeFormat,
  songBlockReOrder,
  get_random_words,
  camalize,
  handleExportBackTrackAndRecordings,
  USDollar,
  months,
  month,
  countDown,
  chordStyles,
  getMobileOperatingSystem,
  detectSystemOs,
  isMobile,
  currencyFormat,
  filterBeats,
  filterItemsByCategory,
  getUniqueCategoryNames,
  handleBuyMore,
  handleOpenWindow
}
