import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Api } from '../../../helper/ApiList'
import { postMethod } from '../../../helper/ApiMethods'
import { ACTION_TYPES } from '../../../hooks/customReducerActionTypes'
import { initialState, postReducer } from '../../../hooks/useApiReducer'
import { ideaAction } from '../../../services/actions/idea/idea.action'
import tabOptions from '../../../utils/tabOptions'
import TabModal from '../../Modal/TabModal'
import { loaderAction } from './../../../services/actions/loader.action'
import { AddIcon, AddMoreBlueIcon } from '../../../helper/icons'
const Ideas = () => {
  const [state, postDispatch] = React.useReducer(postReducer, initialState)
  const { token } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const [active, setActive] = useState('Desire')
  const [selectedGeneratorTab, setSelectedGeneratorTab] = useState('words')

  const handleGeneratorTab = index => {
    setSelectedGeneratorTab(index)
  }

  const getIdeas = async () => {
    let body = {
      ideaType: selectedGeneratorTab,
      ideaName: active
    }
    postDispatch({ type: ACTION_TYPES.FETCH_START })
    dispatch(loaderAction(true))

    const { data, error } = await postMethod(token, Api.getIdea, body)

    if (!error) {
      dispatch(ideaAction(data, selectedGeneratorTab))
      // toast.success(data?.message)

      postDispatch({
        type: ACTION_TYPES.FETCH_SUCCESS,
        payload: { data: data }
      })
    }
    postDispatch({ type: ACTION_TYPES.FETCH_ERROR })
    dispatch(loaderAction(false))

    // error && toast.error(error)
  }

  useEffect(() => {
    getIdeas()
  }, [])

  useEffect(() => {
    getIdeas()
  }, [selectedGeneratorTab, active])

  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12 text-center">
          <div className="Generator-content">
            <p className="mb-0">Mood Idea Generator</p>
            <span
              className={`Generator-tab ${
                selectedGeneratorTab === 'words' && 'active'
              }`}
              onClick={() => handleGeneratorTab('words')}
            >
              Words
            </span>
            <span>|</span>
            <span
              className={`Generator-tab ${
                selectedGeneratorTab === 'phrases' && 'active'
              }`}
              onClick={() => handleGeneratorTab('phrases')}
            >
              Phrases
            </span>
            {/* <div className="edit-icon-right-bar">
              <img
                className="edit-icon-right-bar-inner"
                src="assets/images/pop-out-line.svg"
                alt=""
              />
            </div> */}
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12 text-center">
          <div className="buttons-right-bar">
            <ul>
              {tabOptions?.map((option, index) => (
                <li
                  key={index}
                  className={`right-bar-btn-list ${
                    active === option?.ideaName && 'active'
                  }`}
                  onClick={() => {
                    setActive(option?.ideaName)
                    getIdeas()
                  }}
                >
                  {option?.ideaName}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 text-center">
          <div className="add-btn-right-bar pb-2">
            {/* <img
              onClick={() => setOpenModal(true)}
              className="add-btn-right-bar"
              src="assets/images/add_icon.svg"
              alt=""
            /> */}
            <button
              className="select-all-icon"
              aria-haspopup="true"
              onClick={() => setOpenModal(true)}
            >
              <AddMoreBlueIcon className={`pr-2 gap-2 add-icon`} />{' '}
              <span className="pe-2">Add</span>
            </button>
          </div>
        </div>
      </div>
      <TabModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        ideaType={selectedGeneratorTab}
        ideaName={active}
        getIdeas={getIdeas}
      />
    </>
  )
}

export default Ideas
