/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Player from '../Player/Player'
import MicRecorder from 'mic-recorder-to-mp3'

import { useDispatch, useSelector } from 'react-redux'
import {
  blockRecordingAction,
  getRecordingAction,
  playPauseAction
} from '../../services/actions/mediaPlayerActions/mediaPlayer.action'
import Seekbar from '../Player/Seekbar'

import { useTimer } from '../../hooks/useTimer'
import { getMethod, postFileUploadMethod } from '../../helper/ApiMethods'
import { Api } from '../../helper/ApiList'
import {
  backTrackPlayPauseAction,
  backTrackSelectAction
} from '../../services/actions/mediaPlayerActions/backTrack.action'
import { loaderAction } from '../../services/actions/loader.action'
import BackTrackList from './BackTrackList'
import BackTrackPlayer from './../Player/BackTrackPlayer/BackTrackPlayer'
import { constants, playerConstants } from '../../services/constants'
import axios from 'axios'
import { countDown } from '../../utils/commonFunctions'
import { toast } from '../Toast/Toast'
import {
  PauseCircleOutlineSharpIcon,
  StopCircleOutlinedIcon
} from '../../helper/icons'
import useAudioBacktrackRecorder from '../../hooks/useAudioBacktrackRecorder'
import { useAlert } from 'react-alert'
const Footer = () => {
  const { startAudioSession, stopAudioSession } = useAudioBacktrackRecorder()
  const {
    backTrackActiveSong,
    backTrackIsPlaying,
    isFooterBackTrack
  } = useSelector(state => state.backTrackState)
  const { renderedStreamDuration, startHandler, stopHandler } = useTimer()
  const alert = useAlert()
  const audioContextRef = useRef(null)
  const sourceRef = useRef(null)

  const { isPlaying, activeSong } = useSelector(state => state.playPause)
  const { disabled } = useSelector(state => state.loader)

  const { isFBackTrackActive } = useSelector(
    state => state.isBackTrackEnableState
  )

  const { singleSong } = useSelector(state => state.singleSong)
  const { liveSongList } = useSelector(state => state.liveSongList)
  const { token } = useSelector(state => state.auth)
  const { leftToggle } = useSelector(state => state.leftToggle)
  const { rightToggle } = useSelector(state => state.rightToggle)

  const { isBlockRecording } = useSelector(state => state.isBlockRecording)
  const [activeRecording, setActiveRecording] = useState(null)

  const [duration, setDuration] = useState(0)
  const [seekTime, setSeekTime] = useState(0)
  const [appTime, setAppTime] = useState(0)
  const [volume, setVolume] = useState(0.3)
  const [blobUrl, setBlobUrl] = useState(null)
  const [isRecording, setIsRecording] = useState(false)
  const [recordingAction, setRecordingAction] = useState(false)

  const dispatch = useDispatch()

  const Mp3Recorder = useMemo(() => new MicRecorder({ bitRate: 128 }), [])

  const handlePlayPause = () => {
    if (isBlockRecording) {
      toast.info('Recording is already running...')
      return
    }

    if (backTrackActiveSong?.title && !isFooterBackTrack) {
      dispatch(backTrackSelectAction(!backTrackIsPlaying, backTrackActiveSong)) // if track select is true then back track is automatically play
      dispatch(
        backTrackPlayPauseAction(!backTrackIsPlaying, backTrackActiveSong)
      )

      return
    }
    if (!activeSong?.songName) {
      toast.info('Please select a song')
      return
    } else {
      if (isPlaying) {
        dispatch(playPauseAction(false, activeSong))
      } else {
        dispatch(playPauseAction(true, activeSong))
      }
    }
  }

  const handleNextSong = () => {
    dispatch(playPauseAction(false, activeSong))
  }

  const startMp3Recording = async () => {
    await Mp3Recorder.start()
      .then(() => {
        setIsRecording(true)
        return true
      })
      .catch(e => console.error(e))
  }

  const stopMp3Recording = async () => {
    return await Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        setIsRecording(false)
        return blob
      })
      .catch(e => console.log(e))
  }

  // here we are starting the audio recording and backtracking recording
  const handleStartRecording = async () => {
    if (!singleSong?._id) {
      return toast.error('Please select a song')
    }
    dispatch(loaderAction(true))

    try {
      const { error } = await getMethod(token, Api.recordingLimitStatus)
      if (error) {
        dispatch(loaderAction(false))

        toast.error(error)
        return
      }
    } catch (error) {}
    dispatch(loaderAction(false))

    if (isBlockRecording) {
      toast.info('Recording is already running...')
      return
    }

    // dispatch(blockRecordingAction(true))
    dispatch(playPauseAction(false))

    setRecordingAction(true)

    dispatch({ type: constants.COUNT_DOWN, payload: { countDown: true } })
    if (isFBackTrackActive && isFooterBackTrack) {
      let backTrackUrl = `${Api.getFileInfo}/${backTrackActiveSong?._id}`
      await startAudioSession(backTrackUrl, countDown, dispatch)

      setIsRecording(true)
    } else {
      await countDown(dispatch)
      await startMp3Recording()
    }

    dispatch({ type: constants.COUNT_DOWN, payload: { countDown: false } })
    // backTrackBuffer && (await playSongInLoop(backTrackBuffer))

    startHandler()
  }

  // here we stop recording
  const handleStopRecording = async () => {
    dispatch(loaderAction(true))
    dispatch(backTrackSelectAction(false, null))
    stopHandler()
    //   uploading process
    let fileName = `${singleSong?.songHeading || activeSong?.songName} - Take ${
      liveSongList.length + 1
    }`

    setRecordingAction(false)
    if (isFBackTrackActive && isFooterBackTrack) {
      await stopAudioSession(uploadRecording, fileName)
    } else {
      let recordBlob = await stopMp3Recording()
      let recordUrl = window.URL.createObjectURL(recordBlob)
      setBlobUrl(recordUrl)
      await uploadRecording(recordBlob, fileName)
    }

    dispatch(loaderAction(false))
    setIsRecording(false)

    dispatch(blockRecordingAction(false))
    dispatch(backTrackPlayPauseAction(false, null))
  }

  const uploadRecording = async (blob, fileName) => {
    dispatch(loaderAction(true))

    var myForm = new FormData()
    myForm.append('songName', fileName + '.m4a')
    myForm.append('songDuration', renderedStreamDuration)
    myForm.append('audioFile', blob)

    const { error } = await postFileUploadMethod(
      token,
      Api.uploadFile + '/' + singleSong?._id,
      myForm
    )

    if (error) {
      toast.error(error || 'Something went wrong while uploading')
    } else {
      await handleRecordings()
    }
  }

  const handleRecordings = async () => {
    let url = Api.getRecordings + singleSong?._id
    const { data } = await getMethod(token, url)

    dispatch(getRecordingAction(data))

    let lastSong =
      data?.recordingList.length > 0
        ? data?.recordingList?.slice(-1)[0]
        : {
            isNew: true,
            songName: `${
              singleSong?.songHeading || activeSong?.songName
            } - Take 1.m4a`
          }

    lastSong.isNew = true
    dispatch(playPauseAction(false, lastSong))
  }

  const pdfBuffer = async () => {
    dispatch(loaderAction(true))
    let url = `${Api.getFileInfo}/${activeSong?.recordingId || activeSong?._id}`

    dispatch(playPauseAction(false, activeSong))
    if (activeSong?._id !== activeRecording?._id) {
      setSeekTime(duration)
    }
    try {
      const response = await axios.get(url, {
        responseType: 'arraybuffer' // <- important param
      })

      const blob = new Blob([response.data], { type: 'audio/wav' })
      const blobUrl = window.URL.createObjectURL(blob)
      setBlobUrl(blobUrl)
      setActiveRecording(activeSong)
      setTimeout(() => {
        dispatch(playPauseAction(true, activeSong))
      }, 500)
    } catch (error) {
      console.log(error)
    }
    dispatch(loaderAction(false))
  }

  useEffect(() => {
    if (activeSong?.songName) {
      pdfBuffer()
    }
  }, [activeSong])

  const footerActiveCls = useMemo(() => {
    if (leftToggle && rightToggle) {
      return 'both-sidebar-active'
    }
    if (leftToggle) {
      return 'footer-bottom-active'
    }

    if (rightToggle) {
      return 'right-sidebar-active'
    }
    return ''
  }, [leftToggle, rightToggle])

  const playSongInLoop = async (response, volume = 0.3) => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)()
    }

    if (sourceRef.current) {
      sourceRef.current.stop() // Stop any existing playback
    }

    const arrayBuffer = await response.arrayBuffer()
    const audioBuffer = await audioContextRef.current.decodeAudioData(
      arrayBuffer
    )
    // Create a gain node to control the volume
    const gainNode = audioContextRef.current.createGain()
    gainNode.gain.value = volume // Set the volume (0 to 1, default is 1)

    sourceRef.current = audioContextRef.current.createBufferSource()
    sourceRef.current.buffer = audioBuffer
    sourceRef.current.loop = true
    sourceRef.current.connect(audioContextRef.current.destination)
    sourceRef.current.start()
  }

  const stopSong = () => {
    if (sourceRef?.current) {
      sourceRef.current.stop()
      sourceRef.current.disconnect()
      sourceRef.current = null
    }
    if (audioContextRef?.current) {
      audioContextRef.current.close()
      audioContextRef.current = null
    }
  }

  useEffect(() => {
    return () => {
      stopSong()
    }
  }, [])

  return (
    <div
      style={{ userSelect: 'none' }}
      className={`footer-custom  ${
        activeSong?.songName ? '' : 'is_not_title'
      } ${leftToggle && 'footer-active'} d-flex align-items-center`}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 d-flex align-items-center justify-content-center">
            <div className={`footer-bottom-content d-flex ${footerActiveCls}`}>
              <div className="song-filter-icon">
                <img
                  className=""
                  src="assets/images/music-filter-icon-new.svg"
                  alt=""
                  width={18}
                />
                {activeSong?.songName && <p>{activeSong?.songName || ''}</p>}
              </div>
              {isRecording ? (
                <IconButton
                  className="p-1"
                  onClick={() => handleStopRecording()}
                >
                  <StopCircleOutlinedIcon className="stop-footer-icon" />
                </IconButton>
              ) : (
                <IconButton
                  disabled={disabled}
                  className="p-1"
                  onClick={() => !disabled && handleStartRecording()}
                >
                  <img
                    className=""
                    src="assets/images/record-new-icon.svg"
                    alt=""
                    width={20}
                  />
                </IconButton>
              )}

              {/* <IconButton className="p-1">
                <img
                  className=""
                  src="assets/images/backward-icon-new.svg"
                  alt=""
                  width={22}
                />
              </IconButton> */}
              <IconButton className="p-1" onClick={() => handlePlayPause()}>
                {(!recordingAction && isPlaying) || backTrackIsPlaying ? (
                  <PauseCircleOutlineSharpIcon className="pause-footer-icon" />
                ) : (
                  <img
                    // onClick={() => handlePlayPause()}
                    className=""
                    src="assets/images/play-icon-new.svg"
                    alt=""
                    width={20}
                  />
                )}
              </IconButton>

              {recordingAction ? (
                <span className="take-name text-danger">
                  Recording... {renderedStreamDuration}
                </span>
              ) : (
                <Seekbar
                  value={appTime}
                  min="0"
                  max={duration}
                  onInput={event => setSeekTime(event.target.value)}
                  setSeekTime={setSeekTime}
                  appTime={appTime}
                />
              )}

              <Player
                blobUrl={blobUrl}
                volume={volume}
                seekTime={seekTime}
                onEnded={handleNextSong}
                onTimeUpdate={event => setAppTime(event.target.currentTime)}
                onLoadedData={event => {
                  setDuration(event.target.duration)
                }}
              />

              {/* <VolumeBar
                value={playerVolume}
                min="0"
                max="1"
                onChange={event => handleVolume(event)}
                // onChange={event => setVolume(event.target.value)}
                setVolume={setVolume}
              /> */}
              <div className="drum-custom">
                <BackTrackList isFooter={true} />
              </div>
              <div id="video-preview" className="d-none">
                <video
                  controls
                  playsInline
                  autoPlay
                  muted={false}
                  volume="0"
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Back Track Player */}

      <BackTrackPlayer
        volume={volume}
        seekTime={seekTime}
        onEnded={handleNextSong}
        onTimeUpdate={event => {
          setAppTime(event.target.currentTime)
        }}
        onLoadedData={event => {
          setDuration(event.target.duration)
        }}
      />
    </div>
  )
}

export default Footer
