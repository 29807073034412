const constants = {
  LOADER_START: 'LOADER_START',
  LOADER_STOP: 'LOADER_STOP',
  COUNT_DOWN: 'COUNT_DOWN',

  // authentication

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  REFRESH_TOKEN: 'REFRESH_TOKEN',

  RENDER_AGAIN: 'RENDER_AGAIN',

  DISABLE_ACCESS: 'DISABLE_ACCESS',

  // create new account
  SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',

  // sidebar toggle
  LEFT_SIDEBAR_TOGGLE: 'LEFT_SIDEBAR_TOGGLE',
  RIGHT_SIDEBAR_TOGGLE: 'RIGHT_SIDEBAR_TOGGLE',

  // add folder and song toggle
  FOLDER_TOGGLE: 'FOLDER_TOGGLE',
  SONG_TOGGLE: 'SONG_TOGGLE',
  SONG_BLOCK_TOGGLE: 'SONG_BLOCK_TOGGLE',
  NOTEBOOK_TOGGLE: 'NOTEBOOK_TOGGLE',

  // font color and background color toggle
  COLOR_TOGGLE: 'COLOR_TOGGLE',
  BACKGROUND_COLOR_TOGGLE: 'BACKGROUND_COLOR_TOGGLE',

  // song select toggle
  SELECT_BTN_TOGGLE: 'SELECT_BTN_TOGGLE',

  // song constants
  SELECT_FOLDERS_DATA: 'SELECT_FOLDERS_DATA',
  RESET_SELECT_FOLDERS_DATA: 'RESET_SELECT_FOLDERS_DATA',

  // song constants
  SINGLE_SONG_DATA: 'SINGLE_SONG_DATA',
  SINGLE_SONG: 'SINGLE_SONG',
  RESET_SINGLE_SONG_DATA: 'RESET_SINGLE_SONG_DATA',

  // RECENT SONGS / SONGS / FOLDERS
  RECENT_SONGS: 'RECENT_SONGS',
  ALL_SONGS: 'ALL_SONGS',
  SEARCH_SAVE_SONGS: 'SEARCH_SAVE_SONGS',
  ALL_FOLDERS: 'ALL_FOLDERS',

  // IDEA CONSTANTS
  IDEA_LISTS: 'IDEA_LISTS',
  LOOK_UP_TOGGLE: 'LOOK_UP_TOGGLE',
  IDEA_SEARCH_LISTS: 'IDEA_SEARCH_LISTS'
}

const playerConstants = {
  PLAY_PAUSE: 'PLAY_PAUSE',
  RESET_ACTIVE_SONG: 'RESET_ACTIVE_SONG',
  SINGLE_SONG_RECORDING_DATA: 'SINGLE_SONG_RECORDING_DATA',
  PLAYER_VOLUME: 'PLAYER_VOLUME',

  // song block
  SONG_BLOCK_RECORDING_START: 'SONG_BLOCK_RECORDING_START',
  SONG_BLOCK_RECORDING_RESET: 'SONG_BLOCK_RECORDING_RESET',
  SONG_BLOCK_SELECTED_BACKTRACK: 'SONG_BLOCK_SELECTED_BACKTRACK',

  // BACK TRACKING
  ADD_NEW_BACK_TRACK: 'ADD_NEW_BACK_TRACK',
  ALL_BACKTRACKS: 'ALL_BACKTRACKS',
  BACK_TRACKS_PLAY_PAUSE: 'BACK_TRACKS_PLAY_PAUSE',
  BACK_TRACK_SELECT: 'BACK_TRACK_SELECT',
  BACK_TRACK_BUFFER: 'BACK_TRACK_BUFFER',

  FOOTER_BACKTRACK_ACTIVE: 'FOOTER_BACKTRACK_ACTIVE',
  FOOTER_BACKTRACK_RESET: 'FOOTER_BACKTRACK_RESET'
}

const cartTabConstants = {
  ALL_FAV_TAGS: 'ALL_FAV_TAGS'
}
const chordConstants = {
  CHORD_ONE: 'CHORD_ONE',
  CHORD_TWO: 'CHORD_TWO'
}

const aIAssistantConstants = {
  AI_ASSISTANT_TAB_ACTIVE: 'AI_ASSISTANT_TAB_ACTIVE',
  AI_ASSISTANT_DELETE: 'AI_ASSISTANT_DELETE_BTN_PRESS'
}

export {
  constants,
  playerConstants,
  cartTabConstants,
  chordConstants,
  aIAssistantConstants
}
