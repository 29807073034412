import React, { useMemo, useState } from 'react';
import { Tooltip } from '@mui/material';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

import InputSearch from '../../leftPane/InputSearch';
import BeatList from './BeatList';
import VolumeBar from '../../Player/VolumeBar';
import CartFilter from './CartFilter';
import { getUniqueCategoryNames, handleBuyMore } from '../../../utils/commonFunctions';
import { Api } from '../../../helper/ApiList';
import axios from 'axios';
import { toast } from '../../Toast/Toast';
import { useSelector } from 'react-redux';
import AlertConfirm from '../../Modal/AlertConfirm';
import { alertOptions } from '../../../utils/commonOptions';

const CartWrapper = ({
  beats,
  search,
  handleReset,
  setActiveId,
  activeId,
  setBeats,
  allList,
  handleSearch,
  toggleFavorite,
  activeCard,
  filterItems
}) => {
  const { user } = useSelector(state => state.auth);

  const [selectBeats, setSelectBeats] = useState([]);
  const [loaderId, setLoaderId] = useState([]);
  const [playerVolume, setPlayerVolume] = useState(0.5);
  const [alertOpen, setAlertOpen] = useState(alertOptions);

  const handleParse = async (url, beat) => {
    // Set the loader ID to indicate loading has started
    setLoaderId(prev => [...prev, beat?.item_name]);

    let payload = {
      url: url,
    };

    if (!beat?.isPaid && user?.user?.account_type === "free") {
      payload = {
        url: url,
        limitType: "BEATS",
        userId: user?.user?._id
      };
    }

    try {
      const response = await axios.post(
        Api.proxy,
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      // Get the Blob from the response
      const blob = response.data;

      // Use file-saver to save the Blob
      saveAs(blob, beat?.item_name);

    } catch (error) {
      console.error('Download error:', error);

      let errorMessage = 'An unexpected error occurred';

      if (error?.response?.data instanceof Blob) {
        // If the error response is a Blob, we need to parse it to extract the message
        const blob = error.response.data;
        try {
          const text = await blob.text(); // Convert blob to text
          const jsonError = JSON.parse(text); // Parse the text to JSON
          jsonError?.isLimitError && setAlertOpen({
            open: true,
            message: jsonError?.message || errorMessage
          });
          errorMessage = jsonError?.message || errorMessage; // Extract the message
        } catch (parseError) {
          console.error('Error parsing blob response:', parseError);
        }
      } else {
        errorMessage = error?.message || errorMessage;
      }

      // Display the error message to the user
      toast.info(errorMessage);

      // Remove the loader if there's an error
      setLoaderId(prev => prev.filter(item => item !== beat?.item_name));
    }
    finally {
      return false;
    }
  };


  const handleExport = async (beat) => {
    if (beat?.item_id) {
      let url = beat?.item_sample_path;
      return handleParse(url, beat);
    }
  };

  const handleClose = () => {
    setAlertOpen(alertOptions);
  };

  const buyMore = () => {
    handleBuyMore();
    handleClose();
  };


  return (
    <div className=" beats_container">
      <AlertConfirm handleConfirm={buyMore} isOpen={alertOpen.open} setIsOpen={handleClose} heading={"Upgrade for Full Access"} message={alertOpen.message} rightBtnMessage='Unlock Everything' />
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mt-2 ">
            <div className="buttons-right-bar top">
              <ul className="mb-0">
                {getUniqueCategoryNames(allList).map((ele, index) => (
                  <li
                    key={index}
                    className={`right-bar-btn-list false ${activeCard === ele ? 'active' : ''
                      }`}
                    onClick={() => filterItems(ele)}
                  >
                    {ele}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <section className="outer-bg-volume">
        <div className="container">
          <div className="row">
            <div className="col-10">
              <div className="sound-volume recording-tab">
                <VolumeBar
                  value={playerVolume}
                  min="0"
                  max="1"
                  onChange={event => {
                    setPlayerVolume(event?.target?.value);
                  }}
                  adjustVolume={event => {
                    setPlayerVolume(event);
                  }}
                  className="backTrackVolume"
                />
              </div>
            </div>

            <div
              className="col-2 p-0 text-center d-flex"
              style={{ marginLeft: '' }}
            >
              <div className="share-and-delete d-flex mt-0">
                <Tooltip title="Export" arrow>
                  <img
                    onClick={() => handleExport()}
                    className="cursor"
                    src="assets/images/share-song-new.svg"
                    alt=""
                    width={26}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row mt-2">
          <div className="col-6">
            <div className="script-name d-flex">
              {/* <h6>All Beats</h6>
                <img
                  className="loader-img"
                  src="assets/images/rotate-left-icon-new.svg"
                  alt=""
                /> */}

              <div className="beat-search d-flex">
                <InputSearch
                  onClick={() => handleReset}
                  handleReset={handleReset}
                  handleSearch={handleSearch}
                  value={search}
                  width={180}
                />
              </div>
            </div>
          </div>
          <div className="col-6 beat-options d-flex justify-content-end">
            <div className="text-end select-btn">
              <CartFilter
                type={'recording'}
                setBeats={setBeats}
                allList={allList}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="all-song-list-new mt-2">
            <div className="head-all-beats-list">
              <div className="row">
                <div className="col-7">
                  <p className="m-0">Beats</p>
                </div>
                <div className="col-5">
                  <p className="m-0 text-end">Price</p>
                </div>
              </div>
            </div>
            <div className="full song_list_wrapper beats_wrapper">
              {beats?.length === 0 ? (
                <div className="empty-song-list">No records found</div>
              ) : (
                beats?.map((beat, index) => (
                  <BeatList
                    key={index}
                    beat={beat}
                    setActiveId={setActiveId}
                    activeId={activeId}
                    selectBeats={selectBeats}
                    playerVolume={playerVolume}
                    toggleFavorite={toggleFavorite}
                    handleExport={handleExport}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartWrapper;
