const baseUrl = process.env.REACT_APP_API_URI

const socket_url = process.env.REACT_APP_SOCKET_URL
const beat_base_url = process.env.REACT_APP_BEAT_URL
const environment = process.env.REACT_APP_NODE_ENV

export const Api = {
  createAccount: `${baseUrl}/create_new_account`,
  loginAccount: `${baseUrl}/login_account`,
  deleteAccount: `${baseUrl}/delete_account`,

  userInfo: `${baseUrl}/me`,

  setPassword: `${baseUrl}/set_password`,
  forgetPassword: `${baseUrl}/forget_password`,
  //   folders
  getFolders: `${baseUrl}/folders`,
  getFolderSongs: `${baseUrl}/folder_song/`,
  createFolder: `${baseUrl}/create_folder`,
  updateFolder: `${baseUrl}/update_folder`,
  deleteFolder: `${baseUrl}/delete_folder`,
  deleteSongsUnderFolder: `${baseUrl}/delete_under_folder_song/`,

  // songs
  getSongs: `${baseUrl}/songs`,
  songDetails: `${baseUrl}/song_details/{songId}`,
  createSong: `${baseUrl}/create_song`,
  updateSong: `${baseUrl}/update_song`,
  deleteSong: `${baseUrl}/delete_song`,
  deleteMultipleSong: `${baseUrl}/delete_multiple_songs`,
  moveSongsUnderFolder: `${baseUrl}/move_songs_under_folders`,

  // note book

  getNotebooks: `${baseUrl}/all_notebooks`,
  createNotebook: `${baseUrl}/create_notebook`,
  updateNotebook: `${baseUrl}/update_notebook`,
  deleteNotebook: `${baseUrl}/delete_notebooks`,

  // recent songs
  recentSongs: `${baseUrl}/recent_songs`,

  //   folders
  getSongBlock: `${baseUrl}/songBlocks/`,
  createSongBlock: `${baseUrl}/create_songBlock`,
  importFile: `${baseUrl}/import`,
  updateSongBlock: `${baseUrl}/update_songBlock`,
  deleteSongBlock: `${baseUrl}/delete_songBlock`,
  swapSongBlock: `${baseUrl}/swap_songBlock/`,

  // upload recordings
  uploadFile: `${baseUrl}/upload_file`,
  recordingLimitStatus: `${baseUrl}/recordingLimitStatus`,
  uploadSongBlockFile: `${baseUrl}/upload_song_block_file/`,
  getRecordings: `${baseUrl}/get_recordings/`,
  updateRecordingTitle: `${baseUrl}/update/title/`,
  updateBackTrackTitle: `${baseUrl}/update/backtrack/title/`,
  deleteRecordings: `${baseUrl}/delete_recordings/`,

  // back track
  addBackTrack: `${baseUrl}/add_new_backTrack`,
  getBackTrack: `${baseUrl}/backTracks`,
  deleteBackTrack: `${baseUrl}/delete_backtracks`,

  // file information

  getFileInfo: `${baseUrl}/file-data`,

  // Ideas
  getIdea: `${baseUrl}/get_ideas`,
  addIdea: `${baseUrl}/add_ideas`,
  getRyhmes: `${baseUrl}/get_ryhmes`,

  // Favorites tags
  getFavoriteTags: `${baseUrl}/get_favorite_tags/`,
  addFavorites: `${baseUrl}/add_favorites`,
  deleteFavoritesTag: `${baseUrl}/delete_favorites_tag`,
  get_beats: `${baseUrl}/beats`,
  addToFav: `${baseUrl}/add_to_fav`,
  allFavBeats: `${baseUrl}/all_fav_beats`,

  // Stripe apis

  get_subscriptions: `${baseUrl}/prices`,
  stripe_checkout: `${baseUrl}/checkout`,
  create_beat_checkout: `${baseUrl}/create-checkout-session`,
  retrieve_subscription: `${baseUrl}/retrieve`,
  retrieve_beat_payment: `${baseUrl}/retrieve-beats-payment`,
  refresh_token: `${baseUrl}/refresh_token`,
  cancel: `${baseUrl}/cancel`,

  // chat gpt apis

  generate: `${baseUrl}/generate`,
  saveLyric: `${baseUrl}/save_lyric`,
  updateLyricsTitle: `${baseUrl}/update_title`,
  deleteLyrics: `${baseUrl}/delete_multiple_lyrics`,
  wordLimit: `${baseUrl}/word_limit`,
  getAllAILyricsWithFilter: `${baseUrl}/get_all_ai_lyrics_with_filter`,
  getAllAILyricsWithOutFilter: `${baseUrl}/get_all_ai_lyrics`,
  notebookDetail: `${baseUrl}/notebook_detail/`,

  // for ai titles
  generate_title: `${baseUrl}/generate_title`,
  ai_titles: `${baseUrl}/ai_titles`,
  save_ai_title: `${baseUrl}/save_ai_title`,
  aiDetails: `${baseUrl}/ai_details/`,

  notebookTitle: `${baseUrl}/notebook/ai_titles/{notebookId}`,
  generateLink: `${baseUrl}/generateLink`,
  refed_user_info: `${baseUrl}/refed_user_info/{refedId}`,

  proxy: `${socket_url}/proxy`

  // refed app
}

export { socket_url, beat_base_url, environment }
