import axios from 'axios'
import { windowFeatures } from '../utils/commonOptions'

const getMethod = async (token, url) => {
  try {
    const cancelToken = axios.CancelToken.source()
    const config = {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }

    const { data } = await axios.get(url, config, {
      cancelToken: cancelToken.token
    })
    if (data.statusCode === 200 || data.statusCode === 201) {
      return { data: data.data, rest: data, error: null }
    }
  } catch (error) {
    console.log(error, 'asdfjhas')

    const result = error?.response?.data

    if (result?.statusCode === 403) {
      localStorage.clear()
      sessionStorage.clear()
      window.location.reload()
    }

    if (result?.isLimitError) {
      setTimeout(() => {
        const handle = window.open('/price', 'Price Page', windowFeatures)
        if (!handle) {
          window.alert(
            "The window wasn't allowed to open, This is likely caused by built-in popup blockers."
          )
        }
      }, 500)
    }
    return { error: result?.message, data: null }
  }
}

const postMethod = async (token, url, body) => {
  try {
    const cancelToken = axios.CancelToken.source()
    const config = {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }

    const { data } = await axios.post(url, body, config, {
      cancelToken: cancelToken.token
    })

    if (data.statusCode === 200) {
      return { data: data.data, error: null }
    }

    if (data.statusCode === 201) {
      return { data: data, error: null }
    }
  } catch (error) {
    console.log(error)
    const message = error?.response?.data

    if (message?.isLimitError) {
      setTimeout(() => {
        const handle = window.open('/price', 'Price Page', windowFeatures)
        if (!handle) {
          window.alert(
            "The window wasn't allowed to open, This is likely caused by built-in popup blockers."
          )
        }
      }, 500)
    }
    return {
      data: null,
      error: message?.message || error.message || 'Something went wrong'
    }
  }
}

const putMethod = async (token, url, body) => {
  try {
    const cancelToken = axios.CancelToken.source()
    const config = {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }

    const { data } = await axios.put(url, body, config, {
      cancelToken: cancelToken.token
    })
    if (data.statusCode === 200) {
      return { data: data, error: null }
    }
  } catch (error) {
    // const message = error?.response?.data.message
    const message = error?.response?.data

    if (message?.isLimitError) {
      setTimeout(() => {
        const handle = window.open('/price', 'Price Page', windowFeatures)
        if (!handle) {
          window.alert(
            "The window wasn't allowed to open, This is likely caused by built-in popup blockers."
          )
        }
      }, 500)
    }
    return { error: message?.message || error?.message, data: null }
  }
}

const deleteMethod = async (token, url, body) => {
  try {
    const cancelToken = axios.CancelToken.source()

    const { data } = await axios(
      {
        method: 'DELETE',
        url: url,
        data: body,
        headers: { Authorization: 'Bearer ' + token }
      },
      {
        cancelToken: cancelToken.token
      }
    )

    if (data.statusCode === 200) {
      return { data: data, error: null }
    }
  } catch (error) {
    console.error(error)

    let message = error?.response?.data.message || error?.message

    return { error: message, data: null }
  }
}

// form data post API method
const postFileUploadMethod = async (token, url, body) => {
  try {
    const cancelToken = axios.CancelToken.source()
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'audio/mp3'
      }
    }

    const { data } = await axios.post(url, body, config, {
      cancelToken: cancelToken.token
    })

    if (data.statusCode === 200) {
      return { data: data.data, error: null }
    }

    if (data.statusCode === 201) {
      return { data: data, error: null }
    }
  } catch (error) {
    const message = error?.response?.data

    console.log(error)

    if (message?.isLimitError) {
      setTimeout(() => {
        const handle = window.open('/price', 'Price Page', windowFeatures)
        if (!handle) {
          window.alert(
            "The window wasn't allowed to open, This is likely caused by built-in popup blockers."
          )
        }
      }, 500)
    }
    return { error: message?.message || error.message, data: null }
  }
}

export { getMethod, postMethod, putMethod, deleteMethod, postFileUploadMethod }
