import React from 'react'
import Lottie from 'react-lottie'

import animationData from './animation.json'
const JsonAnimation = () => {
  const defaultOptions = {
    loop: false,
    autoPlay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  return (
    <>
      <Lottie options={defaultOptions} height={600} width={400} />
    </>
  )
}

export default JsonAnimation
